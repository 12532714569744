.tableToolbar {
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  &-search {
    .title {
      width: 100px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }
  .linkButtonGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
}

th button.header-button.plu,
th button.header-button.descripEn,
th button.header-button.descripKo,
th button.header-button.department,
th button.header-button.codeType,
th button.header-button.category {
  font-weight: bold;
}

.keyword {
  display: flex;
  align-items: center;

  .keyword-input {
    position: relative;
    flex-grow: 1;

    Input {
      width: 100%;
    }

    .clear-keyword {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      padding: 0;
    }
  }
}

.editPlu {
  .details {
    .MuiFormControl-root {
      margin-bottom: 1rem;
    }
  }
}

.pluGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .pluOrganic,
  .pluLocal,
  .pluBox {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
  }

  .MuiTypography-root {
    font-weight: 400;
    text-align: left;
  }

  .MuiTypography-title-md {
    font-weight: 700;
  }
}

.itemMasterSettings {
  &-header {
    display: flex;
    justify-content: space-between;
  }
  .MuiSheet-root {
    .searchInput {
      width: 300px;
    }
    .section1 {
      display: flex;
      justify-content: flex-start;
      gap: 1rem;
      .department {
        width: 200px;
      }
      .indicat0 {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .MuiCheckbox-root {
          margin-right: 1.5rem;
        }
      }
    }
    .section2 {
      .indicat1 {
        .indicat1-group1 {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 1rem;
        }
      }
    }
  }
}
