.internalLayout {
  display: flex;
  min-height: 100%;
  &-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    min-height: 100%;
    gap: 1rem;
  }
}
