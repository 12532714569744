.infocenter-sidebar {
    width: 220px;
    background-color: #f4f4f4;
    height: 100vh;
  }
  
  .infocenter-sidebar-header {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  
  .infocenter-sidebar-body {
    padding: 10px;
  }
  
  .infocenter-sidebar .MuiListItemButton-root {
    padding: 10px;
    border-radius: 5px;
  }
  
  .infocenter-sidebar .MuiListItemButton-root:hover {
    background-color: #e0e0e0;
  }
  