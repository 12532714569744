.info-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #f5f5f5;
  }
  
  .info-dashboard-text {
    flex: 1 1 100%;
    margin-left: 0.5rem;
  }
  