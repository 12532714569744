.messageList {
  max-height: 750px; // check if this make sense.
  overflow-y: auto;
}

.messageConfirmation {
  &-container {
    padding: 1rem;
    margin-bottom: 1.5rem;
    .header-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;

      .senderInfo {
        display: flex;
        justify-content: space-between;
        &-detail {
          margin-left: 1rem;
        }
      }
      .buttonGroup {
        display: flex;
        height: 32px;
        flex-direction: row;
        div {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 1rem;
        }
      }
    }
    .requestType-box {
      display: flex;
      padding: 1rem 0 1rem 0;
      flex-direction: column;
      align-items: flex-start;
      .statusDetail {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;
        .status {
          margin-right: 0.5rem;
          display: inline-block;
        }
      }
    }
    .body-box {
      padding: 1rem 0 1rem 0;
      .sanitizedMessage {
        word-wrap: break-word;
      }
      .requestMessage {
        margin: 1rem 0;
        min-height: 2rem;
      }
    }
  }
}

.header-box-divider {
  margin-top: 1rem;
}

.mdmEmailLabel {
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.reply {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &-container {
    min-height: 300px;
    .replyBox {
      width: 100%;
    }
    .replyStack {
      margin: 0.5rem 0;
      border: 1px solid #e0e0e0;
      background-color: #fbfcfe;
      min-height: 250px;
    }
    .replyHeader {
      padding: 0.5rem 0;
    }
    .replyBody {
      min-height: 5rem;
      padding: 1rem;
    }
    .replyAttachments {
      padding: 1rem;
    }
  }
}

.attachments {
  .MuiAspectRatio-root {
    min-width: 80px;
  }
  .attachmentTitle {
    cursor: pointer;
  }
  .attachmentImage {
    cursor: pointer;
  }
}
