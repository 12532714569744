.sidebar {
  min-height: 100dvh;
  width: var(--Sidebar-width);
  top: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 1rem;
  border-right: 1px solid lightgrey;
  z-index: 10000;
  &-overlay {
    width: 100vw;
    height: 100vh;
    background-color: var(--joy-palette-background-backdrop);
    z-index: 9998;
    top: 0;
    left: 0;
    position: fixed;
    transition: opacity 0.4s;
  }
  &-header {
    display: flex;
    gap: 1rem;
    align-items: center;

    button {
      background-color: #fbfcfe;
      padding: 3px;
    }

    svg {
      height: 50px;
      width: auto;
    }
  }
  &-body {
    min-height: 0;
    overflow: hidden auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &-footer {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}
