:root {
    // --Header-height: 3rem !important;  // Add any custom CSS variables as needed
  }
  
  .infoCenterLayout {
    display: flex;
    min-height: 100vh;
    padding: 0;
  
    &-main {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 0;
      height: 100vh;
      gap: 1rem;
      overflow-y: scroll;
    }
  }
  