.editor {
  .options {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-bottom: 0.3rem;
  }
  .editorBox {
    height: 15rem;
    padding: 5px;
    border: 1px solid#d2d7db;
    border-radius: 5px;
    background-color: #e3effb;
    width: 100%;
    overflow-wrap: break-word;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
  }
}
